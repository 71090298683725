
<template>
  <div class="row gy-2">
    <div class="col-12">
      <div class="d-sm-flex mb-4 justify-content-between">
        <h5 class="mb-3 mb-sm-0">Stores</h5>
        <button @click.prevent="popupModalShow = true" class="btn btn-primary" type="button">
          <i class="ri align-bottom ri-add-fill"></i>  Add store </button>
      </div>
      <div v-if="!isLoading" class="row">
        <div class="col-xxl-4 col-sm-6"
          v-for="(store, dIndex) of storeList" :key="dIndex">
            <store-card :store="store">
              <template v-slot:actions>
                <router-link :to="`/stores/${store.id}`" class="btn me-3 btn-primary btn-sm">
                  View </router-link>
                <a @click="deleteItem(store.id)" href="javascript:void(0);" class="btn btn-danger btn-sm">
                  Delete</a>
              </template>
            </store-card>
        </div>
        <div v-if="!storeList.length" class="py-3 text-center">
          <p> You don't have any store. Click the button above to create a store</p>
        </div>
      </div>
      <is-loading v-else /> 
    </div>
    <b-modal size="xl" v-model="popupModalShow" hide-footer centered
      title="Add new store" :no-close-on-backdrop="true"
      title-class="fs-6" @hide="initializeAll()">
      <store-form v-if="popupModalShow" @closeMe="initializeAll()"
      :editMode="false" />
    </b-modal>
  </div>
</template>

<script>
import StoreForm from "@/components/forms/StoreForm.vue"
import StoreCard from "@/components/cards/StoreCard.vue"
import IsLoading from '@/components/IsLoading.vue'

export default {
  name: "store-list",
  components:{
    StoreForm, StoreCard,
    IsLoading
  },
  data() {
    return {
      isLoading:true,
      popupModalShow: false,
    }
  },
  computed: {
    storeList(){
      return this.$store.state.store.list
    },
  },
  methods: {
    deleteItem(itemId) {
      this.confirmDelete({
        text: "You are about to delete a store",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/stores/${itemId}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$store.commit("store/DELETE_STORE", itemId)
            }
          })
        }
      });
    },
    initializeAll(){
      this.popupModalShow = false;
    },
  },
  created(){
    this.$store.dispatch('fetchCategories')
    this.$store.dispatch('fetchStoreTypes')
    this.$store.dispatch("store/fetchStores")
    .then(response => {
      this.isLoading = false
    })
  },
  mounted(){
    if(this.$route.query.create){
      this.popupModalShow = true
    }
  }
}

</script>

